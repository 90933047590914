import React from "react";
import {
  BannerPage,
  ContainerContentFixed,
  ContainerCta,
  InclusionSection,
  InternalLink,
  InternalLinkTypes,
  Layout,
  SEO,
} from "../../components";
import { Row, Col } from "antd";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";

const col1Layout = {
  md: 12,
  xs: 24,
  style: {
    padding: "2rem 1rem",
  },
};

const col2Layout = {
  md: 12,
  xs: 24,
  style: {
    padding: "2rem",
  },
};
const Platinum = () => {
  const data = useStaticQuery(graphql`
    query {
      allPlatinumInclusionsListJson {
        nodes {
          title
          list
        }
      }
      Img1: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/1.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img2: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/3.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img3: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/4.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img4: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/2.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img5: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/6.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img6: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/11.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
      Img7: file(relativePath: { eq: "homedesigns/Lot718QuimbyStWilliamsLanding/17.jpg" }) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  const FluidImg = ({ name, style }) => (
    <Img style={{ ...style }} fluid={data[name].childImageSharp.fluid} />
  );
  const sections = data.allPlatinumInclusionsListJson.nodes;
  return (
    <Layout>
      <SEO
        title="Platinum Inclusions"
        description={"List of our platinum inclusions"}
      />
      <BannerPage
        title={"Our Platinum Inclusions"}
        subTitle={"List of our platinum inclusions"}
      />
      <ContainerContentFixed>
        <Row gutter={[8, 8]} align="middle">
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[0].list}
              title={sections[0].title}
            />
            <InclusionSection
              list={sections[1].list}
              title={sections[1].title}
            />
          </Col>
          <Col {...col2Layout}>
            <FluidImg name={"Img1"} />
          </Col>
        </Row>

        <Row gutter={[8, 8]} align="middle">
          {" "}
          <Col {...col2Layout}>
            <FluidImg name={"Img2"} />
          </Col>
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[2].list}
              title={sections[2].title}
            />
            <InclusionSection
              list={sections[3].list}
              title={sections[3].title}
            />{" "}
            <InclusionSection
              list={sections[4].list}
              title={sections[4].title}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="middle">
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[5].list}
              title={sections[5].title}
            />{" "}
            <InclusionSection
              list={sections[6].list}
              title={sections[6].title}
            />
            <InclusionSection
              list={sections[7].list}
              title={sections[7].title}
            />
          </Col>
          <Col {...col2Layout}>
            <FluidImg name={"Img3"} />
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="middle">
          <Col {...col2Layout}>
            <FluidImg name={"Img4"} />
          </Col>
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[8].list}
              title={sections[8].title}
            />
            <InclusionSection
              list={sections[9].list}
              title={sections[9].title}
            />{" "}
            <InclusionSection
              list={sections[10].list}
              title={sections[10].title}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="middle">
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[11].list}
              title={sections[11].title}
            />
            <InclusionSection
              list={sections[12].list}
              title={sections[12].title}
            />{" "}
            <InclusionSection
              list={sections[13].list}
              title={sections[13].title}
            />{" "}
          </Col>
          <Col {...col2Layout}>
            <FluidImg name={"Img5"} />
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="middle">
          {" "}
          <Col {...col2Layout}>
            <FluidImg name={"Img6"} />
          </Col>
          <Col {...col1Layout}>
            <InclusionSection
              list={sections[14].list}
              title={sections[14].title}
            />{" "}
            <InclusionSection
              list={sections[15].list}
              title={sections[15].title}
            />{" "}
            <InclusionSection
              list={sections[16].list}
              title={sections[16].title}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]} align="middle">
          {" "}

          <Col {...col1Layout}>
            <InclusionSection
              list={sections[17].list}
              title={sections[17].title}
            />{" "}
            <InclusionSection
              list={sections[18].list}
              title={sections[18].title}
            />{" "}
            <InclusionSection
              list={sections[19].list}
              title={sections[19].title}
            />
          </Col>  <Col {...col2Layout}>
          <FluidImg name={"Img7"} />
        </Col>
        </Row>
        <section style={{ textAlign: "center" }}>
          <InternalLink url={InternalLinkTypes.Disclaimer}>
            See our disclaimer
          </InternalLink>
        </section>
      </ContainerContentFixed>
      <ContainerCta
        title={"Got any question?"}
        subTitle={"Talk to our friendly sales team"}
        secondaryText={"Contact Us"}
        secondaryLink={InternalLinkTypes.Contact}
      />
    </Layout>
  );
};

export default Platinum;
